var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"SecurityCodeInputComponent d-flex"},[_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.input",value:({
      isRequired: {
        message: ' ',
      },
    }),expression:"{\n      isRequired: {\n        message: ' ',\n      },\n    }",modifiers:{"input":true}}],staticClass:"SecurityCodeInputComponent__input d-inline-block mr-3",attrs:{"value":_vm.character1,"data-test-id":"security-code-input-1","name":"InputText-1","max":"1","size":"large","autofocus":""},on:{"changevalue":({ detail }) => _vm.onChangeInput(1, detail),"paste":function($event){$event.preventDefault();return _vm.onPaste.apply(null, arguments)}}}),_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.input",value:({
      isRequired: {
        message: ' ',
      },
    }),expression:"{\n      isRequired: {\n        message: ' ',\n      },\n    }",modifiers:{"input":true}}],staticClass:"SecurityCodeInputComponent__input d-inline-block mr-3",attrs:{"value":_vm.character2,"data-test-id":"security-code-input-2","name":"InputText-2","max":"1","size":"large"},on:{"changevalue":({ detail }) => _vm.onChangeInput(2, detail),"paste":function($event){$event.preventDefault();return _vm.onPaste.apply(null, arguments)}}}),_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.input",value:({
      isRequired: {
        message: ' ',
      },
    }),expression:"{\n      isRequired: {\n        message: ' ',\n      },\n    }",modifiers:{"input":true}}],staticClass:"SecurityCodeInputComponent__input d-inline-block mr-3",attrs:{"value":_vm.character3,"data-test-id":"security-code-input-3","name":"InputText-3","max":"1","size":"large"},on:{"changevalue":({ detail }) => _vm.onChangeInput(3, detail),"paste":function($event){$event.preventDefault();return _vm.onPaste.apply(null, arguments)}}}),_c('ui-text-input',{directives:[{name:"validate",rawName:"v-validate.input",value:({
      isRequired: {
        message: ' ',
      },
    }),expression:"{\n      isRequired: {\n        message: ' ',\n      },\n    }",modifiers:{"input":true}}],staticClass:"SecurityCodeInputComponent__input d-inline-block",attrs:{"value":_vm.character4,"data-test-id":"security-code-input-4","name":"InputText-4","max":"1","size":"large"},on:{"changevalue":({ detail }) => _vm.onChangeInput(4, detail),"paste":function($event){$event.preventDefault();return _vm.onPaste.apply(null, arguments)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }