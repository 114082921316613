<template>
  <div class="SecurityCodeInputComponent d-flex">
    <ui-text-input
      v-validate.input="{
        isRequired: {
          message: ' ',
        },
      }"
      :value="character1"
      data-test-id="security-code-input-1"
      name="InputText-1"
      class="SecurityCodeInputComponent__input d-inline-block mr-3"
      max="1"
      size="large"
      autofocus
      @changevalue="({ detail }) => onChangeInput(1, detail)"
      @paste.prevent="onPaste"
    />
    <ui-text-input
      v-validate.input="{
        isRequired: {
          message: ' ',
        },
      }"
      :value="character2"
      data-test-id="security-code-input-2"
      name="InputText-2"
      class="SecurityCodeInputComponent__input d-inline-block mr-3"
      max="1"
      size="large"
      @changevalue="({ detail }) => onChangeInput(2, detail)"
      @paste.prevent="onPaste"
    />
    <ui-text-input
      v-validate.input="{
        isRequired: {
          message: ' ',
        },
      }"
      :value="character3"
      data-test-id="security-code-input-3"
      name="InputText-3"
      class="SecurityCodeInputComponent__input d-inline-block mr-3"
      max="1"
      size="large"
      @changevalue="({ detail }) => onChangeInput(3, detail)"
      @paste.prevent="onPaste"
    />
    <ui-text-input
      v-validate.input="{
        isRequired: {
          message: ' ',
        },
      }"
      :value="character4"
      data-test-id="security-code-input-4"
      name="InputText-4"
      class="SecurityCodeInputComponent__input d-inline-block"
      max="1"
      size="large"
      @changevalue="({ detail }) => onChangeInput(4, detail)"
      @paste.prevent="onPaste"
    />
  </div>
</template>
<script>
import { Validate } from '@emobg/vue-base';

export default {
  name: 'SecurityCodeInputComponent',
  directives: {
    Validate,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      character1: this.value ? this.value[0] : '',
      character2: this.value ? this.value[1] : '',
      character3: this.value ? this.value[2] : '',
      character4: this.value ? this.value[3] : '',
    };
  },
  methods: {
    onChangeInput(index, value) {
      this[`character${index}`] = value;
      this.updateModelValue();
    },
    onPaste(event) {
      if (!event) {
        return;
      }

      const text = event.clipboardData && event.clipboardData.getData('text');
      this.character1 = text[0] || '';
      this.character2 = text[1] || '';
      this.character3 = text[2] || '';
      this.character4 = text[3] || '';
      this.updateModelValue();
    },
    updateModelValue() {
      const modelValue
        = `${this.character1 || ' '}${this.character2 || ' '}${this.character3 || ' '}${this.character4 || ' '}`;
      this.$emit('change', modelValue);
    },
  },
};
</script>
<style lang="scss">
  @import "./SecurityCodeInputComponent";
</style>
